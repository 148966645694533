import type { useInfiniteHits } from 'react-instantsearch-core'
import { useTranslation } from 'next-i18next'

import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'

import { useAnalyticsTracking } from '@/hooks'
import type { AssetModel } from '@/types'

type ShowMoreWidgetProps = {
  isLastPage: ReturnType<typeof useInfiniteHits<AssetModel>>['isLastPage']
  results: ReturnType<typeof useInfiniteHits<AssetModel>>['results']
  showMore: ReturnType<typeof useInfiniteHits<AssetModel>>['showMore']
}

export const ShowMoreWidget = ({ isLastPage, results, showMore }: ShowMoreWidgetProps) => {
  const { trackClickEvent } = useAnalyticsTracking()
  const { t } = useTranslation('download-center', { keyPrefix: 'mainPage.searchAndHitsPanel' })

  const { nbHits = 0, page = 0, hitsPerPage = 0 } = results || {}

  const showMoreButtonText = t('showMoreWidget.showMore')
  const showMoreDataCy = 'Documents-count-showMore'

  const handleShowMoreButton = () => {
    trackClickEvent(showMoreButtonText, showMoreDataCy, 'internal_cta_click')
    showMore()
  }

  let nbHitsFetched = (page + 1) * hitsPerPage
  if (isLastPage) nbHitsFetched = nbHits

  if (nbHits === 0) return null

  return (
    <Box alignSelf="center" display="flex" flexDirection="column" alignItems="center" mb={4}>
      <Box mb={1.5}>
        <Typography
          component="span"
          data-cy="Documents-count-current"
          variant="body3bold"
          color="text.secondary"
        >
          {nbHitsFetched}
        </Typography>
        <Typography
          component="span"
          data-cy="Documents-count-total"
          variant="body3"
          color="text.secondary"
          mb={1.5}
        >
          {` ${t('showMoreWidget.from')} ${t(
            // TODO why cannot i18next figure out the correct key for pl-pl?
            nbHits === 1 ? 'showMoreWidget.item_one' : 'showMoreWidget.item_other',
            { count: nbHits },
          )}`}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        size="medium"
        onClick={handleShowMoreButton}
        disabled={isLastPage}
        startIcon={<KeyboardArrowDown />}
        data-cy={showMoreDataCy}
      >
        {showMoreButtonText}
      </Button>
    </Box>
  )
}
